import React, { Component } from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import colors from '../config/color';
import RingHeader from '../components/ringHeader';

class Homepage extends Component {
  render() {
    return (
      // <Layout showHeader={true} showHero={false}>
      <Layout>
        {/* TODO: SEO */}
        <SEO title="Here Hear 聽聽" />
        {/* <div className="search-bar-up">
          <BigSearchBar categories={data.allWordpressCategory.edges} />
          <Product title={''} edges={data.allWordpressPost.edges} />
          <div className="more-btn-content">
            <Link style={{ textAlign: 'center' }} to={'/posts/page/1'}>
              <div className="more">看更多</div>
            </Link>
          </div>
          <Category edges={data.allWordpressCategory.edges} />
        </div> */}

        <div className="content">
          <RingHeader />
          <h1>關於我們</h1>
          <div className="banner-mask"></div>
        </div>

        <p>
          「情緒是一時的，只要跨過去了，一切都有好轉的可能。」
          有感於台灣與世界上的憂鬱症患者人數不停攀升，自己也曾經受到憂鬱症困擾，Here
          Hear 聽聽創辦人 Lynia 毅然決然在 2019 年推出 Here Hear 聽聽APP。
          <br />
          <br />
          Here Hear 聽聽是一款以 AI 智能情緒辨識演算法，連結 Big Data 大數據的
          APP。出發點是鼓勵使用者說出自己的心聲，並透過使用者所錄製的聲音檔辨識出情緒，從而推薦適合的身心放鬆活動給情緒受困者，運用科技協助用戶處理當下的情緒困擾，從而獲得再出發勇氣。
          <br />
          <br />
          「有時候問題得到傾聽後，就如同向森林裡的樹洞說出秘密，彷彿心情在那一瞬間就能奇蹟似地好轉。」
          你可以把 Here Hear
          聽聽想像成一個樹洞，這個森林裡有一隻沒有眼睛、只會聽的「喜兒鳥」，還有一隻總是溫暖關心你的「喜悅兔」，他們充滿包容接納的心，就是為了傾聽你而存在。
          <br />
          <br />
          歡迎跟我們一起加入 Here Hear 聽聽這個自由的天地，你可以在這裡靜
          靜地等待，說出自己的心聲，Here Hear 聽聽的神奇力量可以療癒傷口
          ，讓我們回歸真實的自我！
        </p>

        <style jsx>{`
          .content {
            background-image: url('/images/happy-businessman-celebrating-victory-at-office-PV4ZPYY.png');
            background-repeat: no-repeat;
            background-position: center;
            width: 100%;
            height: 310px;
            text-align: center;
            position: relative;
            z-index: 0;
          }

          .banner-mask {
            width: 100%;
            height: 250px;
            background-color: ${colors['black']};
            opacity: 0.3;
            position: absolute;
            top: 60px;
            z-index: -1;
          }

          h1 {
            color: ${colors['white']};
            font-family: NotoSansCJKtc-Bold;
            font-size: 24px;
            line-height: 1.33;
            margin-top: 110px;
          }

          p {
            margin-top: 60px;
            color: ${colors['black-p']};
            font-family: NotoSansCJKtc-Regular;
            font-size: 14px;
            line-height: 1.43;
            padding: 0 16px;
          }

          @media (min-width: 768px) {
            .content {
              background-image: url('/images/happy-businessman-riding-bicycle-to-work-in-CQDRP6Z.png');
              background-size: cover;
              height: 400px;
            }

            .banner-mask {
              height: 340px;
            }

            h1 {
              font-size: 48px;
              line-height: 60px;
              margin-top: 140px;
            }

            p {
              font-size: 18px;
              line-height: 1.33;
              padding: 0 50px 0 60px;
            }
          }
        `}</style>
      </Layout>
    );
  }
}

export default Homepage;

export const pageQuery = graphql`
  query {
    allWordpressCategory {
      edges {
        node {
          wordpress_id
          name
          slug
        }
      }
    }
    hotCategory: allWordpressCategory(
      sort: { fields: count, order: DESC }
      limit: 8
    ) {
      edges {
        node {
          wordpress_id
          name
          slug
        }
      }
    }
    allWordpressPost(limit: 20) {
      edges {
        node {
          title
          slug
          categories {
            wordpress_id
            id
            name
            slug
          }
          tags {
            id
            name
            slug
          }
          jetpack_featured_media_url
        }
      }
    }
  }
`;
